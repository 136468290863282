<template>
  <div>
    <vue-type
      title="Tipo de financiación"
      permission-type="tipofinancion"
      url-get-register="/v1/financing/get/"
      url-delete-register="/v1/financing/get/delete"
      url-active-register="/v1/financing/update/active/"
      url-delete-register-one="/v1/financing/delete/"
      url-delete-all="/v1/financing/delete/"
      url-delete-register-back="/v1/financing/delete/restore/"
      url-delete-all-back="/v1/financing/delete/restoreall"
      url-update-one="/v1/financing/update/"
      url-create-one="/v1/financing/create"
    />
  </div>
</template>
<script>
import TypeOpcion from '@/components/optionsCrud/typeIndex.vue'

export default {
  components: {
    'vue-type': TypeOpcion,
  },
}
</script>
